import { authorisationGuard } from '@shared/guards/authorisation.guard';
import { Access } from '@shared/models/auth/Access';
import { areaPerms as areaAccess, InlightsRoute } from '@shared/models/shell/InlightsRoute';

export const imageDefinitionsRouteData: InlightsRoute[] = [

	// Redirect image-definitions-new to image-definitions
	{
		title: 'Image Definitions',
		icon: 'art_track',
		showInMenu: true,
		path: 'image-definitions',
		data: {
			allowAnonymousAccess: false,
			requiredClaims: [areaAccess(x => x.imageDefinitions, [Access.Read, Access.Write])],
		},
		canActivate: [authorisationGuard],
		loadComponent: () => import('./pages/image-definitions.component').then((m) => m.ImageDefinitionsComponent),
		children: [
			{
				title: 'Image Definition Details',
				showInMenu: false,
				path: 'details/:id',
				loadComponent: () => import('./pages/image-definition-details/image-definition-details.component').then((m) => m.ImageDefinitionDetailsComponent),
				canActivate: [authorisationGuard],
				data: {
					allowAnonymousAccess: false,
					requiredClaims: [areaAccess(x => x.imageDefinitions, [Access.Write])],
				},
				children: [{
					title: 'Image Definition Test',
					path: 'test',
					showInMenu: false,
					outlet: 'modalOutlet',
					loadComponent: () => import('./pages/image-definition-details/image-definition-test/image-definition-test.component').then((m) => m.ImageDefinitionTestComponent),
				}]
			},
		]
	},
	{
		path: 'image-definitions-new',
		redirectTo: 'image-definitions',
	},
];
