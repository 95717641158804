import { Data, Route } from '@angular/router';
import { availablePermissions } from '@shared/models/auth/availablePermissions';
import { Access } from '../auth/Access';
import { permNames } from '../auth/permNames';
import { PermissionEntry } from '../auth/PermissionEntry';
import { iAvailablePermissions } from '../auth/iAvailablePermissions';

export declare interface InlightsRouteData extends Data {
	allowAnonymousAccess: boolean;
	requiredClaims: { name: string; value: string[] }[];
}

export declare interface InlightsRoute extends Route {
	title?: string;
	icon?: string;
	svgIcon?: boolean;
	class?: string;
	showInMenu?: boolean;
	data?: InlightsRouteData;
	children?: InlightsRoute[];
}

export declare type InlightsRoutes = InlightsRoute[];


export function areaPerms(callback: (area: iAvailablePermissions) => PermissionEntry, permissionTypes: Access[]): { name: string; value: string[] } {
	const permissionArea = callback(availablePermissions);

	return {
		name: permissionArea.key,
		value: permissionTypes,
	};
}
