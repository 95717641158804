import { authorisationGuard } from '@shared/guards';
import { Access } from '@shared/models/auth/Access';
import { areaPerms, InlightsRoute } from '@shared/models/shell/InlightsRoute';

export const dataSetsImageRoutes: InlightsRoute[] = [
	{
		title: 'Image Data Sets',
		icon: 'photo_library',
		// photo_library burst_mode
		showInMenu: true,
		data: {
			allowAnonymousAccess: false,
			// This must be changed back to data-sets-image
			requiredClaims: [areaPerms(x => x.imageDefinitions, [Access.Read, Access.Write])],
		},
		path: 'data-sets-image', canActivate: [authorisationGuard], loadComponent: () => import('./pages/data-sets-image-index/data-sets-image-index.component').then(m => m.DataSetsImageIndexComponent),
		children: [{
			title: 'New image Data Set',
			path: 'details/new', loadComponent: () => import('./pages/data-sets-image-index/data-set-image-create/data-set-image-create.component').then(m => m.DataSetImageCreateComponent),
		},
		{
			title: 'image Data Set Details',
			path: 'details/:id', loadComponent: () => import('./pages/data-sets-image-index/data-sets-image-details/data-sets-image-details.component').then(m => m.DataSetsImageDetailsComponent),
			children: [
				{ path: 'file-versions/:versionId/validation', loadComponent: () => import('./pages/data-sets-image-index/data-sets-image-details/data-set-image-file-validation/data-set-image-file-validation.component').then(m => m.DataSetImageFileValidationComponent) }
			]
		}
		]
	},
];
