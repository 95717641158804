import { Access } from '@shared/models/auth/Access';
import { AuthGuard as authenticationGuard } from '@auth0/auth0-angular';
import { areaPerms, InlightsRoute } from '@shared/models/shell/InlightsRoute';

export const fontsRoutes: InlightsRoute[] = [
	{
		title: 'Font Management',
		icon: 'brand_family',
		showInMenu: true,
		path: 'fonts',
		loadComponent: () =>
			import('./pages/fonts-index/fonts-index.component').then(
				(m) => m.FontsIndexComponent,
			),
		canActivate: [authenticationGuard],
		data: {
			// requiredClaims: [{ name: availablePermissions.fonts.key, value: ['read', 'write'] }],
			requiredClaims: [areaPerms(x => x.fonts, [Access.Read, Access.Write])],
			allowAnonymousAccess: false,
		},
		children: [
			{
				title: 'Font Details',
				showInMenu: false,
				path: 'details/:id',
				loadComponent: () =>
					import('./pages/fonts-details/fonts-details.component').then(
						(m) => m.FontsDetailsComponent,
					),
			}
		]
	},
];
