import { AuthGuard as authenticationGuard } from '@auth0/auth0-angular';
import { areaPerms, InlightsRoute } from '@shared/models/shell/InlightsRoute';
import { authorisationGuard } from '../../shared/guards/authorisation.guard';
import { Access } from '@shared/models/auth/Access';

export const clientImageTemplatesRouteData: InlightsRoute[] = [
	{
		title: 'Artboards',
		icon: 'artboard',
		svgIcon: true,
		showInMenu: true,
		path: 'artboards',
		data: {
			allowAnonymousAccess: false,
			requiredClaims:[areaPerms(x => x.imageTemplates, [Access.Read, Access.Write])],
		},
		canActivate: [ authorisationGuard],
		loadComponent: () => import('./pages/artboards-index/artboards-index.component').then((m) => m.ArtboardsIndexComponent),

		children: [
			{
				title: 'Artboard Details',
				path: 'details/:id',
				loadComponent: () => import('./pages/artboard-details/artboard-details.component').then((m) => m.ArtboardDetailsComponent),
				canActivate: [authenticationGuard, authorisationGuard],
				// canDeactivate: [checkForUnsavedChangesGuard],
				data: {
					allowAnonymousAccess: false,
					requiredClaims: [areaPerms(x => x.imageTemplates, [Access.Read, Access.Write])],
				},
			},
		],
	},
];
