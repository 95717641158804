import { areaPerms, InlightsRoute } from '@shared/models/shell/InlightsRoute';
import { authorisationGuard } from '../../shared/guards/authorisation.guard';
import { AuthGuard as authenticationGuard } from '@auth0/auth0-angular';
import { Access } from '@shared/models/auth/Access';

export const clientsAreaRouteData: InlightsRoute[] = [
	{
		title: 'Clients',
		icon: 'apartment',
		showInMenu: true,
		path: 'clients',
		loadComponent: () =>
			import('./pages/client-index/client-index.component').then(
				(m) => m.ClientIndexComponent
			),
			canActivate: [authenticationGuard],
			data: {
			requiredClaims:[areaPerms(x => x.clients, [Access.Read, Access.Write])],
			allowAnonymousAccess: false
		},
		children: [
			{
				title: 'Client Details',
				path: 'details/:id',
				loadComponent: () =>
					import('./pages/client-details-page/client-details-page').then(
						(m) => m.ClientDetailsPageComponent)
			},
		],
	},
];
