import { Access } from './Access';
import { iAvailablePermissions } from './iAvailablePermissions';

// Available permissions definition
export const availablePermissions: iAvailablePermissions = {
	clients: { key: 'clients', name: 'Clients', permissions: [Access.Write] },
	email: { key: 'email', name: 'Email', permissions: [Access.Read] },
	developer: { key: 'developer', name: 'Developer', permissions: [Access.Read, Access.Write] },
	imageDefinitions: { key: 'image-definitions', name: 'Image Definitions', permissions: [Access.Read, Access.Write] },
	imageTemplates: { key: 'image-templates', name: 'Image Templates', permissions: [Access.Read, Access.Write] },
	imageTypes: { key: 'image-types', name: 'Image Types', permissions: [Access.Write] },
	imageJobs: { key: 'image-jobs', name: 'Image Jobs', permissions: [Access.Read, Access.Write, Access.Verify, Access.VerifyOwn] },
	imageSets: { key: 'image-sets', name: 'Image Sets', permissions: [Access.Read, Access.Write] },
	clientData: { key: 'client-data', name: 'Client Data', permissions: [Access.Read, Access.Write] },
	userManagement: { key: 'user-management', name: 'User Management', permissions: [Access.Read, Access.Write] },
	fonts: { key: 'fonts', name: 'Font Management', permissions: [Access.Read, Access.Write] },
	packManager: { key: 'pack-manager', name: 'Pack Manager', permissions: [Access.Read, Access.Write] }
};
